export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'region',
    label: 'field.region',
    sortable: true,
    sortField: 'regionId',
    hideToggle: true,
  },
  {
    key: 'agentCode',
    sortable: true,
    sortField: 'agentCode',
    label: 'field.agentCode',
    stickyColumn: true,
    variant: 'light',
    hideToggle: true,
  },
  {
    key: 'name',
    label: 'field.name',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'address',
    label: 'field.address',
    localization: true,
  },
  {
    key: 'contactNumber',
    label: 'field.contactNumber',
  },
  {
    key: 'username',
    label: 'field.username',
  },
  {
    key: 'setupAccount',
    label: 'field.setupAccount',
  },
  {
    key: 'setupBankAccount',
    label: 'field.setupBankAccount',
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updatedDate',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
